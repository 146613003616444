paper.install(window)

window.onload = () => {
  window.appMode = 'loading' // can also be 'info' or 'poems'
  const nCols = 6
  const nRows = 3
  const maxX = window.innerWidth
  const maxY = window.innerHeight
  const maxR = 150
  const colStep = maxX / nCols
  const rowStep = maxY / nRows
  const debug = window.location.search.includes('debug=1')
  let loaded = false
  let vocalPartPlaying = false // so that can trigger next vocal only after
  let shouldRandomlyTriggerVocalSequence = true // only trigger one text sequence per session
  let firstNInteractionsCount = 0;
  const players = []
  const texts = []
  const colors = [
    'rgb(237,	254,	234)',
    'rgb(70,	147,	80)',
    'rgb(244,	186,	227)',
    'rgb(197,	63,	136)',
    'rgb(191,	221,	252)',
    'rgb(79,	164,	219)',
  ]
  const vocalSequences = [
    // [
    //   { text: 'This blood.', color: colors[0], sample: 'blood.mp3' },
    //   { text: 'This loss.', color: colors[1], sample: 'loss.mp3' },
    //   { text: 'This lonesome wind.', color: colors[2], sample: 'wind.mp3' },
    // ],
    // [
    //   { text: 'Foo', color: colors[3], sample: 'voice-test/foo.mp3' },
    //   { text: 'Bar', color: colors[4], sample: 'voice-test/bar.mp3' },
    //   // { text: 'Baz', color: colors[5], sample: 'voice-test/baz.mp3' },
    //   { text: 'Baz', color: colors[5], sample: 'voice/AS3-06.mp3' },
    // ],
    [
      {
        text: 'this body',
        sample: 'voice/AS1-01.mp3',
      },
      {
        text: 'in a box.',
        sample: 'voice/AS1-02.mp3',
      },
      {
        text: 'this blood in the body.',
        color: '#8a0303',
        sample: 'voice/AS1-03.mp3',
      },
      // {
      //   text: 'this wind in the blood.',
      //   sample: 'voice/AS1-04.mp3',
      // },
      // {
      //   text: 'blood.',
      //   sample: 'voice/AS1-05.mp3',
      // },
    ],
    [
      {
        text: 'Light:',
        color: 'rgb(237,	254,	234)',
        textColor: 'black',
        sample: 'voice/AS3-01.mp3',
      },
      {
        text: 'lifted, I stretch my brief body.',
        textColor: 'black',
        sample: 'voice/AS3-02.mp3',
      },
      {
        text: 'Color:',
        color: '#8a0303',
        sample: 'voice/AS3-03.mp3',
      },
      {
        text: 'blaze of day behind blank eyes.',
        sample: 'voice/AS3-04.mp3',
      },
      // {
      //   text: 'Sound:',
      //   color: 'white',
      //   textColor: 'black',
      //   sample: 'voice/AS3-05.mp3',
      // },
      // {
      //   text: 'birds stab greedy beaks into trunk and seed',
      //   textColor: 'black',
      //   sample: 'voice/AS3-06.mp3',
      // },
    ],
    [
      // {
      //   text: 'Moments sweep past.',
      //   sample: 'voice/AS5-01.mp3',
      // },
      {
        text: 'The grass bends',
        sample: 'voice/AS5-02.mp3',
      },
      {
        text: 'then learns',
        sample: 'voice/AS5-03.mp3',
      },
      {
        text: 'to stand',
        color: 'white',
        textColor: 'black',
        sample: 'voice/AS5-04.mp3',
      },
      // {
      //   text: 'to stand again',
      //   textColor: 'black',
      //   sample: 'voice/AS5-05.mp3',
      // },
    ],
  ]
  let vocalSequenceIdx = 0 // which sequence are we playing (1-based, 0 is nothing)
  let vocalSequencePartIdx = 0 // 0 - not playing, [1-3] - playing index
  const canvas = document.getElementById('myCanvas')
  const limiter = new Tone.Compressor(-30, 3).toDestination()
  const compressor = new Tone.Compressor(-20).connect(limiter)

  paper.setup(canvas) // Create an empty project and a view for the canvas:

  window.start = function () {
    console.log('clicked start')
    Tone.start()
    document.querySelector('#start-button').style.opacity = 0
    setTimeout(() => {
      document.querySelector('#start-button').remove()
    }, 500)
    window.appMode = 'app'
  }

  function getGridCoords(x, y) {
    const col = Math.floor(x / colStep)
    const row = Math.floor(y / rowStep)
    return [row, col]
  }

  function loadSamples() {
    // P  -> peles -> circle
    // A  -> agudos -> rect1
    // HV -> harmonia/vibradone -> rect2
    const fns = [
      { sample: 'samples/keyboard/01.mp3', type: 'HV' },
      { sample: 'samples/keyboard/02.mp3', type: 'HV' },
      { sample: 'samples/keyboard/03.mp3', type: 'P' },
      { sample: 'samples/keyboard/04.mp3', type: 'HV' },
      { sample: 'samples/keyboard/05.mp3', type: 'HV' },
      { sample: 'samples/keyboard/06.mp3', type: 'A' },
      { sample: 'samples/keyboard/07.mp3', type: 'HV' },
      { sample: 'samples/keyboard/08.mp3', type: 'P' },
      { sample: 'samples/keyboard/09.mp3', type: 'HV' },
      { sample: 'samples/keyboard/10.mp3', type: 'HV' },
      { sample: 'samples/keyboard/11.mp3', type: 'HV' },
      { sample: 'samples/keyboard/12.mp3', type: 'P' },
      { sample: 'samples/keyboard/13.mp3', type: 'P' },
      { sample: 'samples/keyboard/14.mp3', type: 'A' },
      { sample: 'samples/keyboard/15.mp3', type: 'A' },
      { sample: 'samples/keyboard/16.mp3', type: 'P' },
      { sample: 'samples/keyboard/17.mp3', type: 'A' },
      { sample: 'samples/keyboard/18.mp3', type: 'P' },
    ]

    fns.forEach(({ sample, type }) => {
      const player = new Tone.Player(sample).connect(compressor)
      players.push(player)
      player._shapeType = type
    })

    // load sequences
    vocalSequences.forEach((sequence) => {
      sequence.forEach((part) => {
        const url = 'samples/' + part.sample
        part.player = new Tone.Player(url).connect(compressor)
      })
    })
  }

  function shuffle(array) {
    let currentIndex = array.length,
      randomIndex

    // While there remain elements to shuffle.
    while (currentIndex != 0) {
      // Pick a remaining element.
      randomIndex = Math.floor(Math.random() * currentIndex)
      currentIndex--

      // And swap it with the current element.
      ;[array[currentIndex], array[randomIndex]] = [
        array[randomIndex],
        array[currentIndex],
      ]
    }

    return array
  }

  function drawGrid() {
    for (let i = 1; i < nCols; i++) {
      for (let j = 1; j < nRows; j++) {
        // horizontal lines
        const y = j * rowStep
        const hline = new Path.Line(new Point(0, y), new Point(maxX, y))
        hline.strokeColor = 'white'
        // vertical lines
        const x = i * colStep
        const vline = new Path.Line(new Point(x, 0), new Point(x, maxY))
        vline.strokeColor = 'white'
      }
    }
  }

  function setupUI() {
    const infoIcon = document.getElementById('info-icon')

    const credits = document.getElementById('credits')
    const creditsClose = document.getElementById('credits-close')
    const poemsArrow = document.getElementById('poems-arrow')

    const poems = document.getElementById('poems')
    const socials = document.getElementById('socials')
    const socialsClose = document.getElementById('socials-close')

    infoIcon.onclick = () => {
      console.log('clicked info')
      window.appMode = 'credits'
      infoIcon.style.display = 'none'
      credits.style.display = 'block'
      creditsClose.style.display = 'block'
    }

    creditsClose.onclick = () => {
      console.log('clicked info')
      window.appMode = 'app'
      credits.style.display = 'none'
      creditsClose.style.display = 'none'
      infoIcon.style.display = 'block'
    }

    poemsArrow.onclick = () => {
      console.log('clicked arrow')
      window.appMode = 'poems'
      credits.style.display = 'none'
      creditsClose.style.display = 'none'
      poems.style.display = 'block'
      socials.style.display = 'block'
    }

    socialsClose.onclick = () => {
      console.log('clicked socials close')
      window.appMode = 'app'
      credits.style.display = 'none'
      poems.style.display = 'none'
      socials.style.display = 'none'
      infoIcon.style.display = 'block'
    }
  }

  function setup() {
    if (debug) {
      drawGrid()
      window.players = players
    }
    setKeyboardListeners()

    setupUI()
  }

  function getGrid(rows, cols) {
    let ret = new Array()
    const xStep = maxX / (cols + 1)
    const yStep = maxY / (rows + 1)
    for (let row = 0; row < rows; row++) {
      ret[row] = []
      let y = yStep * (row + 1)
      // const l1 = new Path.Line(new Point(0, y), new Point(maxX, y))
      // l1.strokeColor = 'white'
      for (let col = 0; col < cols; col++) {
        let x = xStep * (col + 1)
        // const l2 = new Path.Line(new Point(x, 0), new Point(x, maxY))
        // l2.strokeColor = 'white'
        ret[row][col] = { x, y }
      }
    }

    // drag
    return ret
  }

  function triggerVocalSequencePart(sequenceIdx, sequencePartIdx) {
    // get current sequence data
    const sequence = vocalSequences[sequenceIdx - 1]
    if (sequencePartIdx > sequence.length) {
      document.body.style.background = 'none'
      texts.forEach((text) => text.remove())
      texts.length = 0
      vocalSequenceIdx = 0
      vocalSequencePartIdx = 0
      return
    }
    const sequencePart = sequence[sequencePartIdx - 1]
    const { text, color, textColor } = sequencePart

    // play audio
    const player = sequence[sequencePartIdx - 1].player
    player.start()
    shouldRandomlyTriggerVocalSequence = false
    vocalPartPlaying = true

    setTimeout(() => {
      // wait 500ms before beingtriggering next sample
      vocalPartPlaying = false
    }, 500)

    const isMobile = window.mobileCheck()
    let x, y
    if (isMobile) {
      const yStep = maxY / (sequence.length + 1)
      y = yStep * (sequencePartIdx - 1) + yStep
      // show text
      // const deltas = [-120, -50, -10, 10, 50, 120]
      // let textX = maxX / 2 + deltas[Math.floor(Math.random() * deltas.length)]
      x = maxX / 2 + Math.random() * 50 - 50
    } else {
      if (sequence.length === 6) {
        const grid = getGrid(4, 6)
        switch (sequencePartIdx) {
          case 1:
            cell = grid[0][0]
            break
          case 2:
            cell = grid[2][1]
            break
          case 3:
            cell = grid[1][2]
            break
          case 4:
            cell = grid[3][3]
            break
          case 5:
            cell = grid[0][4]
            break
          case 6:
            cell = grid[2][4]
            break
        }
        x = cell.x
        y = cell.y
      } else if (sequence.length === 5) {
        const grid = getGrid(3, 5)
        switch (sequencePartIdx) {
          case 1:
            cell = grid[0][0]
            break
          case 2:
            cell = grid[2][1]
            break
          case 3:
            cell = grid[1][2]
            break
          case 4:
            cell = grid[2][3]
            break
          case 5:
            cell = grid[0][4]
            break
        }
        x = cell.x
        y = cell.y
      }
      if (sequence.length === 4) {
        const grid = getGrid(5, 4)
        switch (sequencePartIdx) {
          case 1:
            cell = grid[0][0]
            break
          case 2:
            cell = grid[2][1]
            break
          case 3:
            cell = grid[1][2]
            break
          case 4:
            cell = grid[3][3]
            break
        }
        x = cell.x
        y = cell.y
      } else if (sequence.length === 3) {
        const grid = getGrid(3, 3)
        switch (sequencePartIdx) {
          case 1:
            cell = grid[0][0]
            break
          case 2:
            cell = grid[2][1]
            break
          case 3:
            cell = grid[1][2]
            break
        }
        x = cell.x
        y = cell.y
      }

      x = x + Math.random() * 40 - 20
      y = y + Math.random() * 40 - 20
    }

    var t = new PointText(new Point(x, y))
    t.justification = 'center'
    t.fillColor = textColor ? textColor : 'white'
    t.content = text
    t.fontSize = '20px'
    t.fontFamily = 'Lato'
    t.opacity = 0
    const sampleDuration = player._buffer.duration
    t.tweenTo(
      { opacity: 0.8 },
      { duration: sampleDuration * 1000, easing: 'easeOutQuart' }
    )
    texts.push(t)

    texts.forEach((text) => (text.fillColor = t.fillColor))

    // change background color
    if (color) {
      document.body.style.background = color
    }
  }

  function setKeyboardListeners() {
    document.addEventListener('keydown', (e) => {
      if (window.appMode != 'app') return

      const key = e.key
      window.evt = e
      const keyData = {
        q: '0,0',
        w: '0,1',
        e: '0,2',
        r: '0,3',
        t: '0,4',
        y: '0,5',
        u: '0,6',
        i: '0,7',
        o: '0,8',
        p: '0,9',
        a: '1,0',
        s: '1,1',
        d: '1,2',
        f: '1,3',
        g: '1,4',
        h: '1,5',
        j: '1,6',
        k: '1,7',
        l: '1,8',
        z: '2,0',
        x: '2,1',
        c: '2,2',
        v: '2,3',
        b: '2,4',
        n: '2,5',
        m: '2,6',
      }

      if (vocalSequenceIdx) {
        addCircle(0, 0)
        return
      }

      if (e.key == '1' || e.key == '2' || e.key == '3') {
        const scene = parseInt(e.key)
        console.log('changing scene to', scene)
        vocalSequenceIdx = scene
        vocalSequencePartIdx = 1
        document.body.style.background = 'black'
        triggerVocalSequencePart(vocalSequenceIdx, vocalSequencePartIdx)
        return
      }

      if (e.key == ' ') {
        console.log('shuffling sounds')
        document.body.style.cursor = 'wait'
        setTimeout(() => {
          document.body.style.cursor = 'default'
        }, 100)
        shuffle(players)
        return
      }

      // function to check if argument is a letter
      else if (e.key.match(/[a-z]/i)) {
        const keyboardCoords = keyData[key.toLowerCase()]
        const [row, col] = keyboardCoords.split(',')
        if (row > nRows - 1 || col > nCols - 1) {
          return
        }
        const middleX = col * colStep + colStep / 2
        const middleY = row * rowStep + rowStep / 2
        addCircle(middleX, middleY)
      }
    })
  }

  function initAudio() {
    console.log('init audio...')

    loadSamples()

    Tone.loaded().then(() => {
      setTimeout(() => {
        console.log('loaded all samples')
        document.body.style.cursor = 'default'
        loaded = true
        shuffle(players)
        document.querySelector('.lds-grid').remove()
        document.querySelector('#start-button').style.display = 'block'
      }, 500)
    })
  }

  function initShapes() {
    players.forEach((player) => {
      const deltas = [0, 0, 0, 0, -50, -50, 50, 50, -100, 100, -300, 300]
      const dx = deltas[Math.floor(Math.random() * deltas.length)]
      const dy = deltas[Math.floor(Math.random() * deltas.length)]
      const r = Math.random()
      let type
      if (r < 0.33) {
        type = 'horizontal'
        player._anim_dx = dx
        player._anim_dy = 0
      } else if (r < 0.66) {
        type = 'vertical'
        player._anim_dx = 0
        player._anim_dy = dy
      } else {
        type = 'both'
        player._anim_dx = dx
        player._anim_dy = dy
      }
    })
  }

  // using touchstart events
  canvas.addEventListener('touchstart', function (e) {
    Tone.start()
    e.preventDefault()
    for (let i = 0; i < e.touches.length; i++) {
      const touch = e.touches[i]
      addCircle(touch.clientX, touch.clientY)
    }
  })

  // + using click events
  canvas.addEventListener('click', function (e) {
    Tone.start()
    e.preventDefault()
    addCircle(e.clientX, e.clientY)
  })

  function addCircle(x, y) {
    if (window.appMode !== 'app') return

    // just ignore clicks if in middle of vocal sequence and current vocal part is playing
    if (vocalPartPlaying) {
      return
    }

    // if already in middle of sequence, keep playing next part
    if (vocalSequenceIdx) {
      triggerVocalSequencePart(vocalSequenceIdx, ++vocalSequencePartIdx)
      return
    }

    // check if should play sequence by chance (every 10%)
    // but make sure it doesn't in the first 5 interactions
    if (shouldRandomlyTriggerVocalSequence && Math.random() < 0.1 && firstNInteractionsCount >= 5) {
      document.body.style.background = 'black'
      vocalSequenceIdx = Math.floor(Math.random() * vocalSequences.length) + 1
      vocalSequencePartIdx = 1
      triggerVocalSequencePart(vocalSequenceIdx, vocalSequencePartIdx)
      return
    }

    firstNInteractionsCount++

    const coords = getGridCoords(x, y)
    const sampleIndex = coords[0] * nCols + coords[1]

    // play sound
    let player = players[sampleIndex]
    const sampleDuration = player._buffer.duration
    player.volume.value = -10
    players[sampleIndex].start()

    const shapeType = player._shapeType
    if (shapeType == 'P') {
      const shape = new Shape.Circle({
        center: new Point(x, y),
        radius: 10,
        fillColor: colors[Math.floor(sampleIndex % colors.length)],
      })
      // const rx =
      //   Math.random() > 0.5 ? Math.random() * 100 : -Math.random() * 100
      // const ry =
      //   Math.random() > 0.5 ? Math.random() * 100 : -Math.random() * 100
      // console.log(rx, ry)
      shape.tweenTo(
        {
          'position.x': ['+=', player._anim_dx],
          'position.y': ['+=', player._anim_dy],
          radius: maxR,
          opacity: 0,
          'fillColor.hue': shape.fillColor.hue + 80,
        },
        { duration: sampleDuration * 1000, easing: 'easeOutQuart' }
      )
    } else if (shapeType == 'A') {
      // rectângulo vertical que encolhe - começa com altura máxima e encolhe do centro até desaparecer
      const width = 30
      const height = width * 3
      const shape = new Shape.Rectangle({
        x: x - width / 2,
        y: y - height / 2,
        width,
        height,
        fillColor: colors[Math.floor(sampleIndex % colors.length)],
      })
      shape.tweenTo(
        {
          // 'position.x': ['+=', player._anim_dx],
          // 'position.y': ['+=', player._anim_dy],
          'size.height': 0,
          opacity: 0,
          'fillColor.hue': shape.fillColor.hue + 80,
        },
        { duration: sampleDuration * 1000, easing: 'easeOutQuart' }
      )
    } else if (shapeType == 'HV') {
      // rectângulo horizontal que cresce - expande horizontalmente a partir do centro
      const height = 30
      const width = height * 3
      const shape = new Shape.Rectangle({
        x: x - width / 2,
        y: y - height / 2,
        width,
        height,
        fillColor: colors[Math.floor(sampleIndex % colors.length)],
      })
      shape.tweenTo(
        {
          // 'position.x': ['+=', player._anim_dx],
          // 'position.y': ['+=', player._anim_dy],
          'size.width': width * 2,
          opacity: 0,
          'fillColor.hue': shape.fillColor.hue + 80,
        },
        { duration: sampleDuration * 1000, easing: 'easeOutQuart' }
      )
    }
  }

  setup()
  initAudio()
  initShapes()
}

window.mobileCheck = function () {
  let check = false
  ;(function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true
  })(navigator.userAgent || navigator.vendor || window.opera)
  return check
}
